import { LoginPage } from './pages/LoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/templates/Layout';
import { RequireAuth } from './components/modules/AuthRouter';
import { UserPage } from './pages/UserPage';
import { CreateBusiness } from './pages/BusinessPage/Create';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ResetPasswordSuccessPage } from './pages/ResetPasswordSuccessPage';
import { MarkerPage } from './pages/MarkerPage';
import { EditMarker } from './pages/MarkerPage/Edit';
import { EditBusiness } from './pages/BusinessPage/Edit';
import { ListBusiness } from './pages/BusinessPage';
import { ListMarker } from './pages/MarkerPage/List';
import { CommunityPage } from './pages/Community';
import { ListCommunity } from './pages/Community/List';
import { CommunityEditPage } from './pages/Community/Edit';
import { MilestonesPage } from './pages/MilestonesPage';
import { EditMilestonePage } from './pages/MilestonesPage/edit';
import { CreateMilestone } from './pages/MilestonesPage/create';

export default function App() {
  return (
    <Routes>
      <Route
        path="/admin/*"
        element={
          <RequireAuth>
            <Layout>
              <Routes>
                <Route path="users" element={<UserPage />} />
                <Route path="business" element={<ListBusiness />} />
                <Route path="business/edit/:id" element={<EditBusiness />} />
                <Route path="business/create" element={<CreateBusiness />} />
                <Route path="marker" element={<ListMarker />} />
                <Route path="marker/create" element={<MarkerPage />} />
                <Route path="marker/edit/:id" element={<EditMarker />} />
                <Route path="community" element={<ListCommunity />} />
                <Route path="community/create" element={<CommunityPage />} />
                <Route path="community/edit/:id" element={<CommunityEditPage />} />
                <Route path="milestones" element={<MilestonesPage />} />
                <Route path="milestones/create" element={<CreateMilestone />} />
                <Route path="milestones/edit/:id" element={<EditMilestonePage />} />
                <Route path="*" element={<Navigate to="business" />} />
              </Routes>
            </Layout>
          </RequireAuth>
        }
      />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/reset-password/success" element={<ResetPasswordSuccessPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
