export const MOCK_COLOR = [
  {
    value: '#545454',
    label: 'Default'
  },
  {
    value: '#FD3E3F',
    label: 'Red'
  },
  {
    value: '#1CB08E',
    label: 'Green'
  },
  {
    value: '#F8AB07',
    label: 'Yellow'
  },
  {
    value: '#64218B',
    label: 'Purple'
  }
];

export const COLOR_DEFAULT = '#545454';
