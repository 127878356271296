import { IconButton, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { InputText } from '../../../components/elements/InputText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { loginSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormLoginType = {
  email: string;
  password: string;
};

type FormLoginProps = {
  onSubmit: (data: FormLoginType) => void;
};
export const FormLogin = ({ onSubmit }: FormLoginProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormLoginType>({ resolver: yupResolver(loginSchema) });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack width={'100%'} alignItems={'center'} mb={6}>
        <TitleStyled>LOGIN</TitleStyled>
      </Stack>
      <Stack gap={2.5}>
        <Stack gap={1}>
          <InputText
            inputError={errors.email?.message}
            inputProps={{
              ...register('email')
            }}
            title={'Email'}
          />
        </Stack>
        <Stack gap={1} mb={4}>
          <InputText
            title={'Password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            inputError={errors.password?.message}
            inputProps={{
              ...register('password'),
              type: showPassword ? 'text' : 'password'
            }}
          />
        </Stack>
        <ButtomCustom type={'submit'} label={'Login'} />
      </Stack>
    </form>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: '30px',
  fontWeight: 700,
  color: '#2B3492'
}));
