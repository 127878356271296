import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
const storage = new StorageServices();
/**
 *
 * RequireAuth Component check auth
 *
 * */
export function RequireAuth({ children }: { children: ReactElement }) {
  const isToken = storage.getAccessToken();

  if (!isToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={'/login'} />;
  }

  return children;
}
