export const optionCommunity = [
  {
    value: 'NORTH',
    label: 'NORTH'
  },
  {
    value: 'CENTRAL_EAST',
    label: 'CENTRAL EAST'
  },
  {
    value: 'CENTRAL_WEST',
    label: 'CENTRAL WEST'
  },
  {
    value: 'SOUTH',
    label: 'SOUTH'
  }
];
