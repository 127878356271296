import { Button, Stack, styled, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MarkerFormSchema } from 'utils/validate';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { InputText } from '../../../components/elements/InputText';
import { InputSelect, SelectOptions } from '../../../components/elements/InputSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircleIcon } from '../../../components/elements/CircleIcon';
import { COLOR_DEFAULT, MOCK_COLOR } from '../../BusinessPage/components/mock';
import { PinCustom } from '../../../components/elements/icons/pinCustom';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useFlash } from 'contexts/flash';
import { useLoading } from 'contexts/loading';
import { MarkerOption } from 'types/marker';
import { useNavigate } from 'react-router-dom';

export type TMarkerForm = {
  name: string;
  typeMarker?: string;
  idIcon?: string;
  iconCustom?: File;
  colorIcon?: string;
};

type MarkerFormProps = {
  optionCategories: SelectOptions[];
  optionMarkers: MarkerOption[];
  isEdit?: boolean;
  id?: string;
  initValue?: TMarkerForm;
};
export const MarkerForm = ({
  initValue,
  id,
  optionCategories,
  optionMarkers,
  isEdit
}: MarkerFormProps) => {
  const navigate = useNavigate();
  const { setFlash } = useFlash();
  const { loading } = useLoading();
  const [fileIcon, setFileIcon] = useState<File>();
  const [listIcon, setListIcon] = useState<SelectOptions[]>([]);
  const fileInputIconRef = useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<TMarkerForm>({
    resolver: yupResolver(MarkerFormSchema)
  });
  const [name, idIcon, colorIcon] = watch(['name', 'idIcon', 'colorIcon']);
  const handleChangeType = useCallback(
    (typeMarker: string) => {
      const findListIcon =
        optionMarkers.filter((item) => item.categoryId === typeMarker) || optionMarkers[0];
      const ListIconConvert: SelectOptions[] = findListIcon.map((item: MarkerOption) => ({
        value: item.id,
        label: item.name,
        extra: (
          <CircleIcon
            color={item.color}
            icon={<img height={20} style={{ objectFit: 'cover' }} src={item?.icon} alt={'icon'} />}
          />
        ),
        url: item.icon,
        color: item.color
      }));
      setValue('idIcon', findListIcon[0]?.id);
      setListIcon(ListIconConvert);
    },
    [isEdit, name, optionMarkers]
  );

  useEffect(() => {
    setValue('typeMarker', optionCategories[0]?.value);
    setValue('colorIcon', MOCK_COLOR[0]?.value);
  }, [optionCategories]);

  useEffect(() => {
    if (isEdit) return;
    const findListIcon = optionMarkers.filter((item) => item.categoryId === '1');
    const ListIconConvert: SelectOptions[] = findListIcon.map((item: MarkerOption) => ({
      value: item.id,
      label: item.name,
      extra: (
        <CircleIcon
          color={item.color}
          icon={<img height={20} style={{ objectFit: 'cover' }} src={item?.icon} alt={'icon'} />}
        />
      ),
      url: item.icon,
      color: item.color
    }));
    setListIcon(ListIconConvert);
  }, [optionMarkers]);

  const handleChangeColorIcon = useCallback(
    (idIcon: string) => {
      if (isEdit) {
        const color = listIcon.find((icon) => icon.value === idIcon)?.color;
        setValue('colorIcon', color || COLOR_DEFAULT);
      }
    },
    [isEdit]
  );

  useEffect(() => {
    if (initValue) {
      const findListIcon =
        optionMarkers.filter((item) => item.categoryId === initValue.typeMarker) ||
        optionMarkers[0];
      const ListIconConvert: SelectOptions[] = findListIcon.map((item: MarkerOption) => ({
        value: item.id,
        label: item.name,
        extra: (
          <CircleIcon
            color={item.color}
            icon={<img height={20} style={{ objectFit: 'cover' }} src={item?.icon} alt={'icon'} />}
          />
        ),
        url: item.icon,
        color: item.color
      }));
      setListIcon(ListIconConvert);
      reset(initValue);
      setValue('colorIcon', initValue.colorIcon || '#545454');
    }
  }, [initValue]);
  const handleFileClick = useCallback(() => {
    if (fileIcon) {
      setFileIcon(undefined);
      setValue('idIcon', undefined);
      return;
    }
    if (fileInputIconRef.current) {
      fileInputIconRef.current.click();
    }
  }, [fileIcon]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;
    setValue('iconCustom', fileList[0]);
    setFileIcon(fileList[0]);
  };
  const getImageIconNormal = useMemo(() => {
    return listIcon.find((item) => item.value === idIcon)?.url || listIcon[0]?.url;
  }, [listIcon, idIcon]);
  const handleCreatePin = useCallback(
    async (data: TMarkerForm) => {
      const formData = new FormData();
      let url: string = '';
      if (fileIcon) {
        const blob = new Blob([fileIcon], { type: fileIcon?.type });
        formData.append('file', blob, fileIcon?.name);
        const resUrlRecord = await axios.post('/storages/pins', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (!resUrlRecord.data.url) return;
        url = resUrlRecord.data.url;
      }
      const iconGet =
        optionMarkers.find((item) => item.id === data.idIcon)?.icon || optionMarkers[0].icon;
      const payload = {
        categoryId: Number(data.typeMarker),
        name: data.name,
        color: data.colorIcon,
        icon: url || iconGet
      };
      const { data: res } = await axios.post('/admin/pins', payload);
      const newIcon = {
        value: res.pin.id,
        label: res.pin.name,
        extra: <CircleIcon color={res.pin.color} icon={<img src={res.pin?.icon} alt={'icon'} />} />,
        color: res.pin.color,
        url: res.pin?.icon
      };
      setListIcon([...listIcon, newIcon]);
      setFlash({ type: 'success', message: 'Create Marker Successfully' });
      setFileIcon(undefined);
      navigate('/admin/marker');
    },
    [optionCategories, listIcon, fileIcon]
  );

  const handleDeleteMarker = useCallback(async () => {
    await axios.delete(`/admin/pins/${id}`);
    setFlash({ type: 'success', message: 'Delete Marker Successfully' });
    reset({
      name: '',
      typeMarker: optionCategories[0]?.value,
      idIcon: listIcon[0]?.value,
      colorIcon: MOCK_COLOR[0]?.value
    });
    const newListIcon = listIcon.filter((item) => item.value !== idIcon);
    setListIcon(newListIcon);
  }, [listIcon, id]);

  const { asyncCallback: asyncDeleteMarker } = useAsyncCallback(handleDeleteMarker, []);

  const handleUpdatePin = useCallback(
    async (data: TMarkerForm) => {
      const formData = new FormData();
      let url: string = '';
      if (fileIcon) {
        const blob = new Blob([fileIcon], { type: fileIcon?.type });
        formData.append('file', blob, fileIcon?.name);
        const resUrlRecord = await axios.post('/storages/pins', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (!resUrlRecord.data.url) return;
        url = resUrlRecord.data.url;
      }
      const iconGet =
        optionMarkers.find((item) => item.id === data.idIcon)?.icon || optionMarkers[0].icon;
      const payload = {
        categoryId: Number(data.typeMarker),
        name: data.name,
        color: data.colorIcon,
        icon: url || iconGet
      };
      await axios.put(`/admin/pins/${id}`, payload);
      setFlash({ type: 'success', message: 'Update Marker Successfully' });
      navigate('/admin/marker');
    },
    [fileIcon, optionMarkers, id]
  );
  const { asyncCallback: asyncCreatePin } = useAsyncCallback(
    isEdit ? handleUpdatePin : handleCreatePin,
    []
  );
  return (
    <Stack>
      <form onSubmit={handleSubmit(asyncCreatePin)}>
        <Stack gap={5} flexDirection={'row'} mb={4}>
          <Stack gap={2.5} width={320}>
            <InputText
              placeholder={'Add Marker Name'}
              inputError={errors.name?.message}
              inputProps={{
                ...register('name')
              }}
              title={'Marker Name'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  title={'Marker Icon'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    onChange(e);
                    handleChangeColorIcon(e.target.value as string);
                  }}
                  sx={{
                    height: '63px'
                  }}
                  value={value || listIcon[0]?.value}
                  onBlur={onBlur}
                  options={listIcon}
                />
              )}
              name={'idIcon'}
            />
            <Stack mt={'27px'}>
              <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                <Stack>
                  <Typography mb={1} color={'#2B3492'}>
                    Custom Marker ( svg file )
                  </Typography>
                  <ButtonChooseFile onClick={() => handleFileClick()}>
                    {fileIcon ? 'Clear Maker' : 'Choose Marker'}
                  </ButtonChooseFile>
                </Stack>
                <Stack flexDirection={'row'} gap={5} mt={4}>
                  <Stack alignItems={'center'}>
                    <Stack gap={1} mb={1}>
                      <StyledText>{fileIcon?.name}</StyledText>
                    </Stack>
                    {fileIcon && (
                      <CircleIcon
                        icon={
                          <img
                            height={20}
                            style={{ objectFit: 'cover' }}
                            src={URL.createObjectURL(fileIcon)}
                            alt={'icon'}
                          />
                        }
                      />
                    )}
                    <input
                      accept="image/svg+xml"
                      ref={fileInputIconRef}
                      hidden
                      onChange={(event) => handleFileChange(event)}
                      type="file"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={'47px'} width={320}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  title={'Marker Type'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    onChange(e);
                    handleChangeType(e.target.value as string);
                  }}
                  value={value}
                  onBlur={onBlur}
                  options={optionCategories}
                />
              )}
              name={'typeMarker'}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  sx={{ width: 200, height: '63px' }}
                  title={'Marker Color'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    onChange(e);
                  }}
                  value={value}
                  onBlur={onBlur}
                  options={MOCK_COLOR}
                />
              )}
              name={'colorIcon'}
            />
            <Stack height={'120px'}>
              <Typography mb={1} color={'#2B3492'}>
                Preview
              </Typography>
              <Stack alignItems={'center'}>
                <PinCustom
                  color={colorIcon}
                  icon={
                    fileIcon ? (
                      <img
                        height={20}
                        style={{ objectFit: 'cover' }}
                        src={URL.createObjectURL(fileIcon)}
                        alt={'icon'}
                      />
                    ) : (
                      <img
                        height={20}
                        style={{ objectFit: 'cover' }}
                        src={getImageIconNormal}
                        alt={'icon'}
                      />
                    )
                  }
                />
                <Typography mb={1}>{name}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          width={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'row'}
          gap={4}>
          <ButtomCustom
            style={{ width: 200 }}
            loading={loading}
            type={'submit'}
            label={isEdit ? 'Update' : 'Add'}
          />
          {isEdit && (
            <ButtomCustom
              onClick={asyncDeleteMarker}
              style={{ width: 200, background: 'red' }}
              loading={loading}
              label={'Delete'}
            />
          )}
        </Stack>
      </form>
    </Stack>
  );
};

const ButtonChooseFile = styled(Button)(() => ({
  background: '#2B3492',
  color: '#fff',
  width: 170,
  '&:hover': {
    backgroundColor: '#2B3492',
    opacity: 0.8
  }
}));
const StyledText = styled(Typography)(() => ({
  width: 100,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
