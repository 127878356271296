import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CustomPagination } from 'app/components/elements/Pagination';
import { Header, PagingInfo } from 'types/paging';
import { User } from 'types/user';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { InputSelect, SelectOptions } from '../../../components/elements/InputSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { optionCommunity } from 'const/option';

type AdminUserTableProps = {
  handleChangePage: (value: number) => void;
  pagination: PagingInfo;
  listUser: User[];
  headers: Header[];
  communities: SelectOptions[];
  onResetFilter: () => void;
  optionSearch?: {
    area?: string;
    communityId?: string;
  };
  handleChangeFilter: (fieldName: string, value: string) => void;
  handleResetPoints: () => void;
  onDelete: (id: number) => void;
};
export const AdminUserTable = ({
  handleChangePage,
  listUser,
  pagination,
  headers,
  communities,
  optionSearch,
  handleChangeFilter,
  onResetFilter,
  handleResetPoints,
  onDelete
}: AdminUserTableProps) => {
  return (
    <Box overflow={'auto'} width={'100%'} p={'0 50px'}>
      <Stack gap={2}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
          <Typography>Field Search</Typography>
          <ButtomCustom
            sx={{ width: '100px', height: '30px' }}
            label={'Reset'}
            onClick={onResetFilter}
          />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Stack
            style={{ width: 500, background: '#2B3492' }}
            flexDirection={'row'}
            px={1}
            py={2}
            gap={3}
            mb={3}>
            <InputSelect
              colorTitle={'#fff'}
              placeholder={'Choose Community'}
              title={'Choose Community'}
              onChange={(e: SelectChangeEvent<unknown>) => {
                handleChangeFilter('communityId', e.target.value as string);
              }}
              value={optionSearch?.communityId}
              options={communities}
            />
            <InputSelect
              placeholder={'Choose Area'}
              colorTitle={'#fff'}
              title={'Choose Area'}
              onChange={(e: SelectChangeEvent<unknown>) => {
                handleChangeFilter('area', e.target.value as string);
              }}
              value={optionSearch?.area || ''}
              options={optionCommunity}
            />
          </Stack>
          <ButtomCustom
            type={'button'}
            onClick={handleResetPoints}
            style={{ width: '170px', height: '48px', background: 'red' }}
            label={'Reset Scan'}
          />
        </Stack>
      </Stack>
      <TableWrapper overflow={'auto'}>
        <TableListAdminCompany sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {headers.map((header, idx) => (
                <TableCellHeadStyled key={idx} sx={{ width: header.width || 'unset' }}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'} pl={1}>
                    <Typography>{header.label}</Typography>
                    {header.option}
                  </Stack>
                </TableCellHeadStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser &&
              listUser.map((user, index: number) => {
                return (
                  <TableRowStyled key={index}>
                    <TableCellStyled>
                      <TextWrapper>{user.numberScans}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.email}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{user.address}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack style={{ width: '100%', alignItems: 'center' }}>
                        <ButtomCustom
                          onClick={() => onDelete(Number(user.id))}
                          style={{ width: 150, height: 36, background: 'red' }}
                          label={'Delete User'}
                        />
                      </Stack>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </TableListAdminCompany>
      </TableWrapper>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}
        pt={2.5}>
        {pagination.pageCount !== 0 && (
          <CustomPagination
            page={pagination.currentPage}
            count={pagination.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Box>
  );
};

const TableWrapper = styled(Box)(() => ({
  minHeight: 'calc(100vh - 580px)'
}));

const TableListAdminCompany = styled(Table)(() => ({}));

const TableCellStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  padding: 0,
  fontSize: 16,
  maxWidth: '330px',
  lineHeight: '30px',
  color: 'red',
  height: 60
}));

const TableRowStyled = styled(TableRow)(() => ({
  height: 60,
  '&:hover': {
    background: `linear-gradient(180deg, #FAFDFF 0%, #EEF7FF 9.31%, #D4EAFD 59.58%)`,
    cursor: 'pointer'
  }
}));

const TextWrapper = styled(Typography)(() => ({
  padding: '0 16px',
  color: '#000'
}));

const TableCellHeadStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '17px',
  color: '#FFF',
  padding: 0,
  backgroundColor: '#2B3492'
}));
