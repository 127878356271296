import { Stack } from '@mui/material';
import { LayoutContent } from '../../components/templates/LayoutContent';
import { MilestoneTable } from './components/MilestoneTable';
import { useFlash } from 'contexts/flash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePaging } from 'hooks/usePaging';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Milestone } from 'types/milestones';
import { Header } from 'types/paging';
import { useCallback, useEffect, useState } from 'react';

const HEADER: Header[] = [
  {
    label: 'Message'
  },
  {
    label: 'Point'
  },
  {
    label: 'Action',
    width: '300px'
  }
];
const SIZE_LIST_MILESTONE = 10;
export const MilestonesPage = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listMilestone, setMilestone] = useState<Milestone[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_LIST_MILESTONE
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
  }, []);

  const fetchDataListMarker = useCallback(async (page: string) => {
    const res = await axios.get(`/milestones?page=${page || 1}&size=${SIZE_LIST_MILESTONE}`);
    setMilestone(res.data.milestones as Milestone[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
  }, []);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListMarker, []);

  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
  }, [searchParams.get('page')]);

  const onClickAction = useCallback((id: number) => {
    navigate(`/admin/milestones/edit/${id}`);
  }, []);

  const handleDelete = useCallback(async (id: string) => {
    await axios.delete(`/admin/milestones/${id}`);
    setFlash({ type: 'success', message: 'Delete Marker Successfully' });
  }, []);

  const { asyncCallback: asyncHandleDelete } = useAsyncCallback(handleDelete, []);

  return (
    <Stack width={'100%'}>
      <LayoutContent title={'Milestones List'}>
        <MilestoneTable
          onClickAction={onClickAction}
          listMilestone={listMilestone}
          handleChangePage={handleChangePage}
          headers={HEADER}
          pagination={pagingInfo}
          onDelete={asyncHandleDelete}
        />
      </LayoutContent>
    </Stack>
  );
};
