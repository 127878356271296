import { IconButton, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'types/user';
import { usePaging } from 'hooks/usePaging';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Header } from 'types/paging';
import { AdminUserTable } from './components/AdminUserTable';
import { LayoutContent } from '../../components/templates/LayoutContent';
import { SelectOptions } from '../../components/elements/InputSelect';
import { Community } from 'types/community';
import { DialogConfirm } from '../../components/elements/DialogConfirm';
import { useFlash } from 'contexts/flash';

const SIZE_ADMIN_USER = 10;

export const UserPage = () => {
  const { setFlash } = useFlash();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listUser, setListUser] = useState<User[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_ADMIN_USER
  });
  const [communities, setCommunities] = useState<SelectOptions[]>([]);
  const [showModalResetScan, setShowModalResetScan] = useState<boolean>(false);
  const [idDeleteUser, setIdDeleteUser] = useState<number>();
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const handleChangePage = useCallback(
    (value: number) => {
      setCurrentPage(value);
      searchParams.set('page', value.toString());
      setSearchParams(searchParams);
    },
    [searchParams]
  );
  const handleChangeFilter = useCallback(
    (fieldName: string, value: string) => {
      searchParams.set('page', '1');
      searchParams.set(`${fieldName}`, value);
      setSearchParams(searchParams);
    },
    [searchParams]
  );
  const fetchDataListUser = useCallback(
    async (page: string) => {
      const communityIdParams = searchParams.get('communityId')
        ? `&communityId=${searchParams.get('communityId')}`
        : '';
      const areaParams = searchParams.get('area') ? `&area=${searchParams.get('area')}` : '';
      const sortParams = searchParams.get('sortByScans')
        ? `&sortByScans=${searchParams.get('sortByScans')}`
        : '';
      const res = await axios.get(
        `/admin/users?page=${page || 1}${communityIdParams}${areaParams}${sortParams}&size=${SIZE_ADMIN_USER}`
      );
      setListUser(res.data.users as User[]);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
    },
    [searchParams]
  );

  const handleFetchCommunity = useCallback(async () => {
    const optionCommunities = await axios.get(`/community`);
    const resCommunities = optionCommunities.data.communities as Community[];
    const comunitiesConvert = resCommunities.map((item: Community) => ({
      value: item.id,
      label: item.name
    }));
    setCommunities(comunitiesConvert);
  }, []);

  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListUser, []);
  const { asyncCallback: asyncFetchCommunity } = useAsyncCallback(handleFetchCommunity, []);

  useEffect(() => {
    asyncFetchCommunity();
  }, []);

  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
    }, [searchParams]);

  const onResetFilter = useCallback(() => {
    searchParams.delete('communityId');
    searchParams.delete('area');
    setSearchParams(searchParams);
  }, [searchParams]);

  const handleSortByArea = useCallback(() => {
    const valueSort =
      !searchParams.get('sortByScans') || searchParams.get('sortByScans') === 'asc'
        ? 'desc'
        : 'asc';
    searchParams.set('sortByScans', valueSort);
    setSearchParams(searchParams);
  }, [searchParams]);

  const HEADER_QUESTIONS: Header[] = [
    {
      label: 'Number Of Scans',
      option: (
        <IconButton onClick={handleSortByArea} sx={{ width: '40px', height: '40px' }}>
          <img
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'cover' }}
            src={'/icons/icon_sort.svg'}
          />
        </IconButton>
      )
    },
    {
      label: 'Name'
    },
    {
      label: 'Email'
    },
    {
      label: 'Address'
    },
    {
      label: 'Action'
    }
  ];

  const handleResetScan = useCallback(async () => {
    await axios.delete('/admin/reset-visited-business');
    setShowModalResetScan(false);
    setFlash({ type: 'success', message: 'Reset data scan user successfully' });
    if (searchParams.get('page') && searchParams.get('page') !== '1') {
      searchParams.set(`page`, '1');
      setSearchParams(searchParams);
    }
    const listUserConvert = listUser.map((user) => ({ ...user, numberScans: 0 }));
    setListUser(listUserConvert);
  }, [searchParams, listUser]);

  const handleDelete = useCallback(async () => {
    await axios.delete(`/admin/users/${idDeleteUser}`);
    setFlash({ type: 'success', message: 'Delete User Successfully' });
    setShowModalDelete(false);
    const newListUser = listUser.filter((item) => Number(item.id) !== idDeleteUser);
    setListUser(newListUser);
  }, [idDeleteUser]);
  const { asyncCallback: asyncHandleDelete } = useAsyncCallback(handleDelete, []);

  return (
    <Stack width={'100%'}>
      <LayoutContent title={'User List'}>
        <AdminUserTable
          onDelete={(id: number) => {
            setIdDeleteUser(id);
            setShowModalDelete(true);
          }}
          handleResetPoints={() => setShowModalResetScan(true)}
          communities={communities}
          headers={HEADER_QUESTIONS}
          listUser={listUser}
          handleChangeFilter={handleChangeFilter}
          optionSearch={{
            area: searchParams.get('area') || '',
            communityId: searchParams.get('communityId') || ''
          }}
          pagination={pagingInfo}
          handleChangePage={handleChangePage}
          onResetFilter={onResetFilter}
        />
        <DialogConfirm
          isDelete
          isOpen={showModalResetScan}
          onSubmit={handleResetScan}
          onClose={() => setShowModalResetScan(false)}
          textButton={'Reset'}
          textContent={
            'You will lose the following:\n' +
            '• User data for number of scans on the admin and on the app\n' +
            '• Business data for number of times each business has been scanned'
          }
          textTitle={'Are you sure you want to reset all the scan data from the system?'}
        />
        <DialogConfirm
          isDelete
          isOpen={showModalDelete}
          onSubmit={asyncHandleDelete}
          onClose={() => setShowModalDelete(false)}
          textButton={'Delete'}
          textContent={'Are you sure you want to delete this user and all their data?'}
          textTitle={'Delete User'}
        />
      </LayoutContent>
    </Stack>
  );
};
