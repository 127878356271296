// @ts-nocheck
import { Button, IconButton, Stack, styled, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { unionBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { pinFormSchema } from 'utils/validate';
import { InputText } from '../../../components/elements/InputText';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { InputSelect, SelectOptions } from '../../../components/elements/InputSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { Business, TStorage } from 'types/marker';
import { useLoading } from 'contexts/loading';
import { optionCommunity } from 'const/option';
import FormHelperText from '@mui/material/FormHelperText';
import { TextError } from '../../../components/elements/TextError';

export type TPinForm = {
  name: string;
  address?: string;
  latitude: number;
  longitude: number;
  point: number;
  description?: string;
  files?: File[];
  pinId?: string;
  communityId?: string;
  colorIcon?: string;
  area: string;
  qrLongitude: number | undefined;
  qrLatitude: number | undefined;
  qrUrl: string;
  fileQr?: string;
};
type PinFormProps = {
  optionMarkers: SelectOptions[];
  communities: SelectOptions[];
  handleSubmitForm: (value: TPinForm & { uploaded?: TStorage[] }) => void;
  isEdit?: boolean;
  initValue?: Business;
};
export const PinForm = ({
  optionMarkers,
  communities,
  handleSubmitForm,
  isEdit,
  initValue
}: PinFormProps) => {
  const { loading } = useLoading();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm<TPinForm>({ resolver: yupResolver(pinFormSchema) });
  const [listFile, setListFile] = useState<File[]>([]);
  const [listFileUploaded, setListFileUploaded] = useState<TStorage[]>([]);

  useEffect(() => {
    if (initValue) {
      reset(initValue);
      setListFileUploaded(initValue.businessPhotos?.map((item) => item.photo) || []);
    }
  }, [initValue]);

  useEffect(() => {
    if (initValue?.pinId) return;
    setValue('pinId', optionMarkers[0]?.value);
  }, [optionMarkers]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const fileConvertArray = Array.from(fileList);
    const files = unionBy([...listFile, ...fileConvertArray], 'name');
    setValue('files', files);
    setListFile(files);
    // @ts-expect-error set empty input
    event.target.value = null;
  };
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onSubmit = useCallback(
    async (data: TPinForm) => {
      handleSubmitForm({ ...data, uploaded: listFileUploaded });
    },
    [listFileUploaded]
  );

  const handleRemoveItem = useCallback(
    (index: number, type?: 'uploaded' | 'new') => {
      if (type === 'uploaded') {
        const newList = [...listFileUploaded];
        newList.splice(index, 1);
        setListFileUploaded(newList);
        return;
      }
      const newList = [...listFile];
      newList.splice(index, 1);
      setListFile(newList);
      setValue('files', newList);
      if (!fileInputRef.current) return;
      // @ts-expect-error set empty input
      fileInputRef.current.value = null;
    },
    [listFile, listFileUploaded]
  );
  return (
    <Stack width={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={5} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'} mb={4}>
          <Stack
            gap={1}
            border={'1px solid #ccc'}
            boxShadow={'0 10px 15px -3px rgba(0,0,0,0.1)'}
            flexGrow={3}
            p={5}>
            <Stack gap={5} flexDirection={'row'}>
              <Stack gap={2.5} maxWidth={320} width={'100%'}>
                <InputText
                  inputError={errors.name?.message}
                  inputProps={{
                    ...register('name')
                  }}
                  title={'Business Name'}
                />
                <InputText
                  inputError={errors.address?.message}
                  inputProps={{
                    ...register('address')
                  }}
                  title={'Address'}
                />
                <InputText
                  inputError={errors.description?.message}
                  inputProps={{
                    ...register('description'),
                    minRows: 7,
                    maxRows: 7,
                    multiline: true,
                    sx: { padding: 0 }
                  }}
                  title={'About'}
                />
              </Stack>
              <Stack gap={2.5} maxWidth={320} width={'100%'}>
                <InputText
                  inputError={errors.latitude?.message}
                  inputProps={{
                    ...register('latitude')
                  }}
                  title={'Latitude'}
                />
                <InputText
                  inputError={errors.longitude?.message}
                  inputProps={{
                    ...register('longitude')
                  }}
                  title={'Longitude'}
                />
                <InputText
                  inputError={errors.point?.message}
                  inputProps={{
                    ...register('point')
                  }}
                  title={'Points earned when scanned'}
                />
                <Stack>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <InputSelect
                        title={'Choose Area'}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          onChange(e);
                        }}
                        value={value}
                        onBlur={onBlur}
                        options={optionCommunity}
                      />
                    )}
                    name={'area'}
                  />
                  <FormHelperText
                    error={!!errors.area?.message}
                    sx={{ ml: 0, pt: 0.5, height: 24 }}>
                    <TextError errorText={errors.area?.message || ''} />
                  </FormHelperText>
                </Stack>
                <Stack>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <InputSelect
                        title={'Choose Community'}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          onChange(e);
                        }}
                        value={value}
                        onBlur={onBlur}
                        options={communities}
                      />
                    )}
                    name={'communityId'}
                  />
                  <FormHelperText
                    error={!!errors.area?.message}
                    sx={{ ml: 0, pt: 0.5, height: 24 }}>
                    <TextError errorText={errors.community?.message || ''} />
                  </FormHelperText>
                </Stack>
              </Stack>
            </Stack>
            <Stack borderTop={'1px solid #ccc'} pt={2} flexDirection={'row'} gap={5}>
              <Stack maxWidth={320} width={'100%'}>
                <Typography mb={1} color={'#2B3492'}>
                  Image Files
                </Typography>
                <ButtonChooseFile onClick={() => handleFileClick()}>Choose Files</ButtonChooseFile>
                <Stack gap={1} mt={1}>
                  {listFileUploaded?.map((item, index) => (
                    <Stack key={index} flexDirection={'row'} gap={1}>
                      <TextFileStyled>{item.fileName}</TextFileStyled>
                      <IconButton onClick={() => handleRemoveItem(index, 'uploaded')}>
                        <img src={'/icons/icon_cancel.svg'} alt={'icon'} />
                      </IconButton>
                    </Stack>
                  ))}
                  {listFile &&
                    listFile?.map((item: File, index: number) => (
                      <Stack key={index} flexDirection={'row'} gap={1}>
                        <TextFileStyled>{item.name}</TextFileStyled>
                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <img src={'/icons/icon_cancel.svg'} alt={'icon'} />
                        </IconButton>
                      </Stack>
                    ))}
                </Stack>
                <input
                  accept="image/*"
                  ref={fileInputRef}
                  hidden
                  onChange={handleFileChange}
                  type="file"
                  multiple
                />
              </Stack>
              <Stack maxWidth={320} width={'100%'}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputSelect
                      title={'Add Marker'}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        onChange(e);
                      }}
                      sx={{
                        height: '63px'
                      }}
                      value={value}
                      onBlur={onBlur}
                      options={optionMarkers}
                    />
                  )}
                  name={'pinId'}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            gap={1}
            flexGrow={1}
            border={'1px solid #ccc'}
            boxShadow={'0 10px 15px -3px rgba(0,0,0,0.1)'}
            p={5}>
            <Stack gap={5} flexDirection={'row'}>
              <Stack maxWidth={320} width={'100%'} gap={2}>
                <InputText
                  inputError={errors.qrLatitude?.message}
                  inputProps={{
                    ...register('qrLatitude')
                  }}
                  title={'QR Latitude'}
                />
                <InputText
                  inputError={errors.qrLongitude?.message}
                  inputProps={{
                    ...register('qrLongitude')
                  }}
                  title={'QR Longitude'}
                />
                <InputText
                  inputError={errors.qrUrl?.message}
                  inputProps={{
                    ...register('qrUrl')
                  }}
                  title={'QR Url'}
                />
                {initValue?.fileQr && (
                  <img
                    width={'100%'}
                    height={'100%'}
                    style={{ objectFit: 'cover' }}
                    alt={'image-qr-code'}
                    src={initValue.fileQr}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          width={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'row'}
          gap={4}>
          <ButtomCustom
            loading={loading}
            style={{ width: 200 }}
            type={'submit'}
            label={isEdit ? 'Update' : 'Add'}
          />
        </Stack>
      </form>
    </Stack>
  );
};

const ButtonChooseFile = styled(Button)(() => ({
  background: '#2B3492',
  color: '#fff',
  width: 170,
  '&:hover': {
    backgroundColor: '#2B3492',
    opacity: 0.8
  }
}));

const TextFileStyled = styled(Typography)(() => ({
  width: 250,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
