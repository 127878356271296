import { LayoutContent } from '../../components/templates/LayoutContent';
import { Stack } from '@mui/material';
import { MarkerForm } from './components/MarkerForm';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { SelectOptions } from '../../components/elements/InputSelect';
import { MarkerOption, OptionCategory } from 'types/marker';

export const MarkerPage = () => {
  const [optionCategories, setOptionCategories] = useState<SelectOptions[]>([]);
  const [optionMarkers, setOptionMarkers] = useState<MarkerOption[]>([]);

  const handleFetchOption = useCallback(async () => {
    const [optionType, optionMarker] = await Promise.all([
      axios.get('/admin/pin-categories'),
      axios.get('/admin/pins?size=50')
    ]);
    const resCategories = optionType.data.categories;
    const resMarker = optionMarker.data.pins as MarkerOption[];
    const categoriesConvert = resCategories.map((option: OptionCategory) => ({
      value: option.id,
      label: option.name
    }));
    setOptionCategories(categoriesConvert);
    setOptionMarkers(resMarker);
  }, []);

  const { asyncCallback: asyncFetchOption } = useAsyncCallback(handleFetchOption, []);

  useEffect(() => {
    asyncFetchOption();
  }, []);

  return (
    <LayoutContent isBorder title={'Create'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <MarkerForm optionCategories={optionCategories} optionMarkers={optionMarkers} />
      </Stack>
    </LayoutContent>
  );
};
