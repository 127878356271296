import { LayoutContent } from '../../components/templates/LayoutContent';
import { Stack } from '@mui/material';
import { MilestoneForm, TMilestoneForm } from './components/MilestoneForm';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useNavigate } from 'react-router-dom';

export const CreateMilestone = () => {
  const navigate = useNavigate();
  const { setFlash } = useFlash();
  const handleSubmit = useCallback(async (value: TMilestoneForm) => {
    await axios.post(`/admin/milestones`, value);
    setFlash({ type: 'success', message: 'Milestones update successful' });
    navigate('/admin/milestones');
  }, []);

  const { asyncCallback: onSubmit } = useAsyncCallback(handleSubmit, []);

  return (
    <LayoutContent isBorder title={'Create Milestone'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <MilestoneForm onSubmit={onSubmit} />
      </Stack>
    </LayoutContent>
  );
};
