import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const config = {
  secure: process.env.NODE_ENV !== 'development'
};
/**
 * @return TokenStorage service
 */
export class StorageServices {
  /**
   * @returns function handle set token in cookies
   */
  setAccessToken(accessToken: string) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getAccessToken(): string | undefined {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeAccessToken() {
    return Cookies.remove(ACCESS_TOKEN_KEY);
  }
  /**
   * @returns function handle set token in cookies
   */
  setRefreshToken(accessToken: string) {
    Cookies.set(REFRESH_TOKEN_KEY, accessToken, config);
  }

  /**
   * @returns function handle get token in cookies
   */
  getRefreshToken(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * @returns function handle remove token in cookies
   */
  removeRefreshToken() {
    return Cookies.remove(REFRESH_TOKEN_KEY);
  }
}
