import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { usePaging } from 'hooks/usePaging';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Header } from 'types/paging';
import { LayoutContent } from '../../../components/templates/LayoutContent';
import { useFlash } from 'contexts/flash';
import { MarkerOption } from 'types/marker';
import { ListMarkerTable } from './ListMarkerTable';
import { DialogConfirm } from '../../../components/elements/DialogConfirm';

const SIZE_LIST_Maker = 10;

const HEADER: Header[] = [
  {
    label: 'Name'
  },
  {
    label: 'Type'
  },
  {
    label: 'Preview'
  },
  {
    label: 'Action',
    width: '300px'
  }
];
export const ListMarker = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [listMarker, setListMarker] = useState<MarkerOption[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_LIST_Maker
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
    }, []);

  const fetchDataListMarker = useCallback(async (page: string) => {
    const res = await axios.get(`/admin/pins?page=${page || 1}&size=${SIZE_LIST_Maker}`);
    setListMarker(res.data.pins as MarkerOption[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
    }, []);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListMarker, []);

  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
    }, [searchParams.get('page')]);

  const onClickAction = useCallback((id: string) => {
    navigate(`/admin/marker/edit/${id}`);
  }, []);

  const handleDelete = useCallback(async () => {
    await axios.delete(`/admin/pin/${idDelete}`);
    setShowModalConfirmDelete(false);
    const newList = listMarker.filter((item) => item.id !== idDelete);
    setListMarker(newList);
    setFlash({ type: 'success', message: 'Delete Community Successfully' });
    const page = Number(searchParams.get('page'));
    if (newList.length === 0) {
      setSearchParams({ page: (page - 1).toString() });
    }
    setFlash({ type: 'success', message: 'Delete Marker Successfully' });
  }, [idDelete, listMarker, searchParams.get('page')]);

  const { asyncCallback: asyncHandleDelete } = useAsyncCallback(handleDelete, []);

  return (
    <Stack width={'100%'}>
      <LayoutContent title={'Marker List'}>
        <ListMarkerTable
          onClickAction={onClickAction}
          listMarker={listMarker}
          handleChangePage={handleChangePage}
          headers={HEADER}
          pagination={pagingInfo}
          onDelete={(id: string) => {
            setShowModalConfirmDelete(true);
            setIdDelete(id);
          }}
        />
        <DialogConfirm
          isDelete
          isOpen={showModalConfirmDelete}
          onSubmit={asyncHandleDelete}
          onClose={() => setShowModalConfirmDelete(false)}
          textButton={'Delete'}
          textContent={'Are you sure you want to delete this content?'}
          textTitle={'Delete Marker'}
        />
      </LayoutContent>
    </Stack>
  );
};
