import * as React from 'react';
import { ChangeEvent } from 'react';
import { Pagination, PaginationItem, styled } from '@mui/material';

type CustomPaginationProps = {
  page: number;
  count: number;
  onChangePage: (value: number) => void;
};
export const CustomPagination = ({ page, count, onChangePage }: CustomPaginationProps) => {
  const handlePageChange = (e: ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  return (
    <Pagination
      color="primary"
      count={count}
      page={page}
      onChange={handlePageChange}
      renderItem={(item) => <CustomPaginationItem {...item} />}
    />
  );
};
const CustomPaginationItem = styled(PaginationItem)(() => ({
  color: '#770BA1',
  fontWeight: 400,
  margin: '0 10px 0 10px',
  fontSize: '20px',
  '&.Mui-selected': {
    color: '#770BA1 !important',
    backgroundColor: '#D090D7 !important'
  },
  '&.MuiPaginationItem-previousNext': {
    margin: '0 40px 0 40px'
  },
  '.MuiSvgIcon-root': {
    fontSize: '2em'
  }
}));
