import { Stack } from '@mui/material';
import { FormLogin, FormLoginType } from 'app/pages/LoginPage/components/FormLogin';
import axios from 'axios';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageServices } from 'services/storage';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
const storage = new StorageServices();

export const LoginPage = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const postLogin = useCallback(async (data: FormLoginType) => {
    const res = await axios.post('/auth/signin', data);
    setFlash({ type: 'success', message: 'You are successfully logged in' });
    storage.setAccessToken(res.data.accessToken);
    storage.setRefreshToken(res.data.refreshToken);
    navigate('/admin/users');
    // eslint-disable-next-line
  }, []);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(postLogin, []);

  return (
    <Stack>
      <Stack pt={8.375} pb={3.5} width={'100%'} alignItems={'center'} minHeight={'100vh'}>
        <Stack px={{ xs: 3.125, sm: 0 }} mt={3} width={'100%'} maxWidth={{ xs: 'unset', sm: 402 }}>
          <Stack width={'100%'} alignItems={'center'} mb={8}>
            <img alt={'logo'} width={300} src={'/images/logo.png'} />
          </Stack>
          <FormLogin onSubmit={handleSubmitForm} />
        </Stack>
      </Stack>
    </Stack>
  );
};
