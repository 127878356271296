import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ButtonProps } from '@mui/material/Button';
import { SubmitButton, SubmitButtonProps } from './SubmitButton';
import { CircularProgress } from '@mui/material';

type CustomButtonProps = {
  label?: string;
  loading?: boolean;
  isDelete?: boolean;
} & ButtonProps &
  SubmitButtonProps;

/**
 * @returns Element PrimaryButton
 */
export const ButtomCustom: FC<CustomButtonProps> = ({ label, loading, isDelete, ...props }) => {
  return (
    <PrimaryButtonStyled buttondelete={isDelete} {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {label && (
            <Typography fontSize={16} fontWeight={700} color={'#fff'}>
              {label}
            </Typography>
          )}
          {props.children}
        </>
      )}
    </PrimaryButtonStyled>
  );
};

const PrimaryButtonStyled = styled(SubmitButton)(
  ({ buttondelete }: { buttondelete?: boolean }) => ({
    borderRadius: 20,
    width: '100%',
    height: 50,
    background: buttondelete ? 'red' : '#2B3492',
    '&:hover': {
      backgroundColor: buttondelete ? 'red' : '#2B3492',
      opacity: 0.8
    }
  })
);
