import { Stack, Typography } from '@mui/material';

export const ResetPasswordSuccessPage = () => {
  return (
    <Stack>
      <Stack pt={8.375} pb={3.5} width={'100%'} alignItems={'center'} minHeight={'100vh'}>
        <Stack px={{ xs: 3.125, sm: 0 }} mt={3} width={'100%'} maxWidth={{ xs: 'unset', sm: 402 }}>
          <Stack width={'100%'} alignItems={'center'} mb={8}>
            <img alt={'logo'} width={300} src={'/images/logo.png'} />
          </Stack>
          <Stack>
            <Typography fontSize={'18px'} color={'#2B3492'} textAlign={'center'} fontWeight={700}>
              Your password has been successfully reset. Please log in to the app again with your
              new password.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
