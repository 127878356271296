import { IconButton, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { InputText } from '../../../components/elements/InputText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { resetPasswordSchema } from 'utils/validate';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormResetPassword = {
  password: string;
  confirmPassword: string;
};

type FormLoginProps = {
  onSubmit: (data: FormResetPassword) => void;
};
export const ResetPasswordForm = ({ onSubmit }: FormLoginProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormResetPassword>({ resolver: yupResolver(resetPasswordSchema) });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack width={'100%'} alignItems={'center'} mb={6}>
        <TitleStyled>RESET PASSWORD</TitleStyled>
      </Stack>
      <Stack gap={2.5}>
        <Stack gap={1} mb={4}>
          <InputText
            title={'New Password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            inputError={errors.password?.message}
            inputProps={{
              ...register('password'),
              type: showPassword ? 'text' : 'password'
            }}
          />
        </Stack>
        <Stack gap={1} mb={4}>
          <InputText
            title={'Confirm Password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            inputError={errors.confirmPassword?.message}
            inputProps={{
              ...register('confirmPassword'),
              type: showConfirmPassword ? 'text' : 'password'
            }}
          />
        </Stack>
        <ButtomCustom type={'submit'} label={'Submit'} />
      </Stack>
    </form>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: '30px',
  fontWeight: 700,
  color: '#2B3492'
}));
