import { FC, ReactNode, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { TextError } from './TextError';

export type InputProps = {
  value: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | number) => void;
  onBlur?: () => void;
  autoComplete?: string;
};

export type RequiredInputProps = InputProps & { required: true };

type InputTextProps = {
  title?: string;
  inputProps: InputProps | RequiredInputProps | OutlinedInputProps;
  inputError?: string;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  errorWithoutText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement | null> | null;
  maxLength?: number;
};

/**
 * @returns Element InputText
 */
export const InputText: FC<InputTextProps> = ({
  title,
  inputProps,
  inputError,
  placeholder,
  startAdornment,
  endAdornment,
  errorWithoutText = false,
  inputRef
}) => {
  return (
    <FormControl fullWidth error={!!inputError}>
      {title && (
        <FormLabel>
          <Typography mb={1} color={'#2B3492'}>
            {title}
          </Typography>
        </FormLabel>
      )}
      <InputStyled
        {...inputProps}
        autoComplete={inputProps?.autoComplete || 'off'}
        endAdornment={endAdornment}
        inputProps={{
          ref: inputRef
        }}
        placeholder={placeholder}
        startAdornment={startAdornment}
      />
      <FormHelperText error={!!inputError} sx={{ ml: 0, pt: 0.5, height: 24, maxWidth: '100%' }}>
        {inputError && !errorWithoutText && <TextError errorText={inputError} />}
      </FormHelperText>
    </FormControl>
  );
};

const InputStyled = styled(OutlinedInput)(() => ({
  minHeight: 56,
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    padding: '16px 12px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderBottom: `1px solid #ccc`,
    boxShadow: 'inset 0 5px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: 4
  }
}));
