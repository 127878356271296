import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CustomPagination } from 'app/components/elements/Pagination';
import { Header, PagingInfo } from 'types/paging';
import { MarkerOption } from 'types/marker';
import { useNavigate } from 'react-router-dom';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { PinCustom } from '../../../components/elements/icons/pinCustom';

type ListMarkerTableProps = {
  handleChangePage: (value: number) => void;
  pagination: PagingInfo;
  listMarker: MarkerOption[];
  headers: Header[];
  onClickAction: (id: string) => void;
  onDelete: (id: string) => void;
};
export const ListMarkerTable = ({
  handleChangePage,
  listMarker,
  pagination,
  headers,
  onClickAction,
  onDelete
}: ListMarkerTableProps) => {
  const navigate = useNavigate();

  return (
    <Box overflow={'auto'} width={'100%'} p={'25px 50px'}>
      <Stack width={'100%'} alignItems={'flex-end'} justifyContent={'flex-end'} mb={4}>
        <ButtomCustom
          sx={{ width: '170px', height: '48px' }}
          label={'Create'}
          onClick={() => {
            navigate('/admin/marker/create');
          }}
        />
      </Stack>
      <TableWrapper overflow={'auto'}>
        <Table sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {headers.map((header, idx) => (
                <TableCellHeadStyled key={idx} sx={{ width: header.width || 'unset' }}>
                  {header.label}
                </TableCellHeadStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listMarker &&
              listMarker.map((marker, index: number) => {
                return (
                  <TableRowStyled key={index}>
                    <TableCellStyled>
                      <TextWrapper>{marker?.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{marker?.category?.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>
                        <Stack py={1}>
                          <Stack alignItems={'center'}>
                            <PinCustom
                              color={marker?.color}
                              icon={
                                <img
                                  height={20}
                                  style={{ objectFit: 'cover' }}
                                  src={marker?.icon}
                                  alt={'icon'}
                                />
                              }
                            />
                            <Typography mt={1}>{marker?.name}</Typography>
                          </Stack>
                        </Stack>
                      </TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack
                        p={'0 16px'}
                        gap={2}
                        width={'100%'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <ButtomCustom
                          onClick={() => onClickAction(marker.id)}
                          style={{ width: 100, height: 36 }}
                          label={'Edit'}
                        />
                        <ButtomCustom
                          type={'button'}
                          onClick={() => onDelete(marker.id)}
                          style={{ width: 100, height: 36, background: 'red' }}
                          label={'Delete'}
                        />
                      </Stack>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </Table>
      </TableWrapper>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}
        pt={2.5}>
        {pagination.pageCount !== 0 && (
          <CustomPagination
            page={pagination.currentPage}
            count={pagination.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Box>
  );
};

const TableWrapper = styled(Box)(() => ({
  minHeight: 'calc(100vh - 580px)'
}));

const TableCellStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  padding: 0,
  fontSize: 16,
  maxWidth: '330px',
  lineHeight: '30px',
  color: 'red',
  height: 60
}));

const TableRowStyled = styled(TableRow)(() => ({
  height: 60,
  '&:hover': {
    background: `linear-gradient(180deg, #FAFDFF 0%, #EEF7FF 9.31%, #D4EAFD 59.58%)`,
    cursor: 'pointer'
  }
}));

const TextWrapper = styled(Typography)(() => ({
  padding: '0 16px',
  color: '#000'
}));

const TableCellHeadStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '17px',
  color: '#FFF',
  padding: 0,
  backgroundColor: '#2B3492'
}));
