import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { usePaging } from 'hooks/usePaging';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Header } from 'types/paging';
import { LayoutContent } from '../../../components/templates/LayoutContent';
import { useFlash } from 'contexts/flash';
import { ListCommunityTable } from './components/ListCommunityTable';
import { Community } from 'types/community';
import { DialogConfirm } from '../../../components/elements/DialogConfirm';

const SIZE_LIST_Community = 10;

const HEADER: Header[] = [
  {
    label: 'Name'
  },
  {
    label: 'Area'
  },
  {
    label: 'Latitude'
  },
  {
    label: 'Longitude'
  },
  {
    label: 'Action',
    width: '300px'
  }
];
export const ListCommunity = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [listCommunity, setListCommunity] = useState<Community[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_LIST_Community
  });
  const handleChangePage = useCallback((value: number) => {
    setCurrentPage(value);
    setSearchParams({ page: value.toString() });
    // eslint-disable-next-line
    }, []);

  const fetchDataListCommunity = useCallback(async (page: string) => {
    const res = await axios.get(`/admin/community?page=${page || 1}&size=${SIZE_LIST_Community}`);
    setListCommunity(res.data.communities as Community[]);
    setCurrentPage(Number(page));
    setTotal(res.data.total);
    // eslint-disable-next-line
    }, []);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListCommunity, []);

  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
    }, [searchParams.get('page')]);

  const onClickAction = useCallback((id: string) => {
    navigate(`/admin/community/edit/${id}`);
  }, []);

  const handleDelete = useCallback(async () => {
    await axios.delete(`/admin/community/${idDelete}`);
    setShowModalConfirmDelete(false);
    const newList = listCommunity.filter((item) => item.id !== idDelete);
    setListCommunity(newList);
    setFlash({ type: 'success', message: 'Delete Community Successfully' });
    const page = Number(searchParams.get('page'));
    if (newList.length === 0) {
      setSearchParams({ page: (page - 1).toString() });
    }
  }, [idDelete, listCommunity, searchParams.get('page')]);

  const { asyncCallback: asyncHandleDelete } = useAsyncCallback(handleDelete, []);

  return (
    <Stack width={'100%'}>
      <LayoutContent title={'Community List'}>
        <ListCommunityTable
          onClickAction={onClickAction}
          listCommunity={listCommunity}
          handleChangePage={handleChangePage}
          headers={HEADER}
          pagination={pagingInfo}
          onDelete={(id: string) => {
            setShowModalConfirmDelete(true);
            setIdDelete(id);
          }}
        />
        <DialogConfirm
          isDelete
          isOpen={showModalConfirmDelete}
          onSubmit={asyncHandleDelete}
          onClose={() => setShowModalConfirmDelete(false)}
          textButton={'Delete'}
          textContent={'Are you sure you want to delete this content?'}
          textTitle={'Delete Community'}
        />
      </LayoutContent>
    </Stack>
  );
};
