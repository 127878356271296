import { Dialog, IconButton, Stack, styled, Typography } from '@mui/material';
import { ButtomCustom } from './ButtonCustom';
import { useLoading } from 'contexts/loading';
type DialogConfirmType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  textTitle: string;
  textContent: string;
  textButton: string;
  isDelete?: boolean;
};
export const DialogConfirm = ({
  isOpen,
  onClose,
  onSubmit,
  textContent,
  textTitle,
  textButton,
  isDelete
}: DialogConfirmType) => {
  const { loading } = useLoading();

  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ borderRadius: '20px' }} fullWidth>
      <WrapperDialog width={'100%'}>
        <Stack width={'100%'} flexDirection={'row'} p={1.375} justifyContent={'space-between'}>
          <Stack width={'33px'} />
          <IconButton onClick={onClose} sx={{ width: '40px', height: '40px' }}>
            <img src={'/icons/icon_cancel.svg'} />
          </IconButton>
        </Stack>
        <Stack width={'100%'} px={4.375} pb={6.25} alignItems={'center'} gap={2.5}>
          <TitleStyled>{textTitle}</TitleStyled>
          <ContentStyled textAlign={'left'} px={2.875}>
            {textContent}
          </ContentStyled>
          <ButtomCustom
            loading={loading}
            sx={{ width: 200 }}
            isDelete={isDelete}
            label={textButton}
            onClick={onSubmit}
            fullWidth
          />
        </Stack>
      </WrapperDialog>
    </Dialog>
  );
};

const WrapperDialog = styled(Stack)(() => ({
  borderRadius: '20px'
}));
const TitleStyled = styled(Typography)(() => ({
  fontSize: '26px',
  fontWeight: 700,
  color: '#2B3492',
  textAlign: 'center'
}));
const ContentStyled = styled(Typography)(() => ({
  fontSize: '20px',
  color: '#000',
  fontWeight: 400,
  whiteSpace: 'pre-line'
}));
