import { Stack } from '@mui/material';
import { useFlash } from 'contexts/flash';
import { useCallback } from 'react';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { FormResetPassword, ResetPasswordForm } from './components/ResetPasswordForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { setFlash } = useFlash();
  const [searchParams] = useSearchParams();
  const onResetPassword = useCallback(
    async (data: FormResetPassword) => {
      const token = searchParams.get('token');
      if (!searchParams.get('token')) return;
      await axios.post('auth/reset-password', {
        password: data.password,
        token
      });
      setFlash({ type: 'success', message: 'Reset Password successfully' });
      navigate('/reset-password/success');
      // eslint-disable-next-line
    }, [searchParams.get('token')]);

  const { asyncCallback: handleSubmitForm } = useAsyncCallback(onResetPassword, []);

  return (
    <Stack>
      <Stack pt={8.375} pb={3.5} width={'100%'} alignItems={'center'} minHeight={'100vh'}>
        <Stack px={{ xs: 3.125, sm: 0 }} mt={3} width={'100%'} maxWidth={{ xs: 'unset', sm: 402 }}>
          <Stack width={'100%'} alignItems={'center'} mb={8}>
            <img alt={'logo'} width={300} src={'/images/logo.png'} />
          </Stack>
          <ResetPasswordForm onSubmit={handleSubmitForm} />
        </Stack>
      </Stack>
    </Stack>
  );
};
