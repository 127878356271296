import { LinearProgress, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLoading } from 'contexts/loading';

type SideMenu = {
  label: string;
  link: string;
  iconActive?: string;
  iconInactive?: string;
  hidden?: boolean;
  paths?: string[];
  items?: SideMenu[];
};

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { loading: callbackLoading } = useLoading();
  const isMobile = useMediaQuery('(max-width:900px)');

  const MENU: SideMenu[] = useMemo(() => {
    return [
      {
        label: 'Users',
        link: '/admin/users'
      },
      {
        label: 'Businesses',
        link: '/admin/business'
      },
      {
        label: 'Markers',
        link: '/admin/marker'
      },
      {
        label: 'Communities',
        link: '/admin/community'
      },
      {
        label: 'Milestones',
        link: '/admin/milestones'
      }
    ];
  }, []);

  return (
    <Stack
      minHeight={'100vh'}
      flexDirection={isMobile ? 'column' : 'row'}
      width={'100%'}
      position={'relative'}>
      {callbackLoading && <LinearLoading color="success" />}
      <WrapperNavbar py={5} px={1.5}>
        <img
          style={{ cursor: 'pointer' }}
          alt={'img'}
          width={143}
          src={'/images/logo.png'}
          onClick={() => navigate('/admin')}
        />
        <Wrapper width={'100%'} gap={1.5} mt={8}>
          {MENU.map((item, index) => {
            if (item.hidden) return;
            return (
              <NavLink to={item.link} key={index}>
                <WrapperItem className={'button-navbar'} gap={'14px'}>
                  {item.iconActive && (
                    <img
                      className={'icon-active'}
                      width={47}
                      height={47}
                      style={{ display: 'none' }}
                      src={item.iconActive}
                      alt={'icon-menu'}
                    />
                  )}
                  {item.iconInactive && (
                    <img
                      className={'icon-inactive'}
                      width={47}
                      height={47}
                      src={item.iconInactive}
                      alt={'icon-menu'}
                    />
                  )}
                  <TextStyled className={'text-menu'}>{item.label}</TextStyled>
                </WrapperItem>
              </NavLink>
            );
          })}
        </Wrapper>
      </WrapperNavbar>
      <Stack
        sx={{ background: '#F3F5FA' }}
        width={'100%'}
        alignItems={'center'}
        ml={isMobile ? 'unset' : '253px'}>
        {children}
      </Stack>
    </Stack>
  );
};

const Wrapper = styled(Stack)(() => ({
  '.active': {
    '.button-navbar': {
      background: `#2B3492 !important`,
      opacity: '1 !important',
      '.icon-inactive': {
        display: 'none'
      },
      '.icon-active': {
        display: 'block !important'
      },
      '.text-menu': {
        color: '#fff'
      }
    }
  }
}));

const LinearLoading = styled(LinearProgress)(() => ({
  position: 'fixed',
  width: '100vw',
  zIndex: 1000
}));
const WrapperNavbar = styled(Stack)(() => ({
  width: 255,
  background: '#fff',
  alignItems: 'center',
  height: '100vh',
  boxShadow: '6px 0px 15px 0px rgba(63, 0, 88, 0.50)',
  zIndex: 5,
  position: 'fixed',
  overflow: 'auto'
}));

const WrapperItem = styled(Stack)(() => ({
  width: '100%',
  background: '#2B3492',
  opacity: 0.7,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  gap: '14px',
  padding: '12px',
  height: '140px'
}));
const TextStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: '700',
  color: '#fff'
}));
