import { LayoutContent } from '../../../components/templates/LayoutContent';
import { Stack } from '@mui/material';
import { CommunityForm, CommunityTable } from '../components/CommunityTable';
import { useFlash } from 'contexts/flash';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { TStorage } from 'types/marker';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Community } from 'types/community';

export const CommunityEditPage = () => {
  const { id } = useParams();
  const { setFlash } = useFlash();

  const [initValueCommunity, setInitValueCommunity] = useState<Community>();

  const handleFetchInit = useCallback(async () => {
    const { data: res } = await axios.get(`admin/community/${id}`);
    const resCommunity = res.community as Community;
    setInitValueCommunity(resCommunity);
  }, [id]);

  const { asyncCallback: asyncFetchInit } = useAsyncCallback(handleFetchInit, []);

  useEffect(() => {
    asyncFetchInit();
  }, []);

  const handleOnUpdate = useCallback(async (data: CommunityForm & { uploaded?: TStorage[] }) => {
    const formData = new FormData();
    let arrayIdFile: number[] = [];
    if (data.files) {
      for (let x = 0; x < data.files.length; x++) {
        const convertBlob = new Blob([data.files[x]], {
          type: data.files[x]?.type
        });
        formData.append('files', convertBlob, data.files[x]?.name);
      }
      const resListIdFile = await axios.post('/storages/community', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      arrayIdFile = resListIdFile.data.files;
    }
    const listFileUploaded = data.uploaded?.map((item) => Number(item.id));
    const totalArrayListFileUpdatedAndNew = [...arrayIdFile, ...(listFileUploaded || [])];
    const payload = { ...data, storageIds: totalArrayListFileUpdatedAndNew || undefined };
    await axios.put(`/admin/community/${id}`, payload);
    setFlash({ type: 'success', message: 'Update community Successfully' });
  }, []);
  const { asyncCallback: asyncSubmitForm } = useAsyncCallback(handleOnUpdate, []);

  return (
    <LayoutContent isBorder title={'Edit Community'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <CommunityTable isEdit handleSubmitForm={asyncSubmitForm} initValue={initValueCommunity} />
      </Stack>
    </LayoutContent>
  );
};
