import { useLoading } from 'contexts/loading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MilestoneSchema } from 'utils/validate';
import { Stack } from '@mui/material';
import { InputText } from '../../../components/elements/InputText';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { useEffect } from 'react';

export type TMilestoneForm = {
  point: number;
  message: string;
};

type MilestoneFormProps = {
  isEdit?: boolean;
  onSubmit: (value: TMilestoneForm) => void;
  initData?: TMilestoneForm;
};
export const MilestoneForm = ({ isEdit, initData, onSubmit }: MilestoneFormProps) => {
  const { loading } = useLoading();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<TMilestoneForm>({ resolver: yupResolver(MilestoneSchema), defaultValues: initData });

  useEffect(() => {
    reset(initData);
  }, [initData]);

  return (
    <Stack width={450} alignItems={'center'}>
      <InputText
        inputError={errors.point?.message}
        inputProps={{
          ...register('point'),
          type: 'number'
        }}
        title={'Point'}
      />
      <InputText
        inputError={errors.message?.message}
        inputProps={{
          ...register('message')
        }}
        title={'Message'}
      />
      <ButtomCustom
        loading={loading}
        style={{ width: 200 }}
        onClick={handleSubmit(onSubmit)}
        label={isEdit ? 'Update' : 'Add'}
      />
    </Stack>
  );
};
