import { useState, useContext, createContext, FC, ReactNode } from 'react';

type LoadingContext = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

type LoadingProps = {
  children: ReactNode;
};

const LoadingContext = createContext<LoadingContext>({
  loading: false,
  setLoading: () => null
});

/**
 * @return useContext use context loading
 */
export function useLoading(): LoadingContext {
  return useContext(LoadingContext);
}
/**
 * @return LoadingProvider provider value loading
 */
export const LoadingProvider: FC<LoadingProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};
