import { IconButton, Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { usePaging } from 'hooks/usePaging';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { Header } from 'types/paging';
import { Business } from 'types/marker';
import { LayoutContent } from '../../components/templates/LayoutContent';
import { ListBusinessTable } from './components/ListBusinessForm';
import { useFlash } from 'contexts/flash';
import { DialogConfirm } from '../../components/elements/DialogConfirm';
import { Community } from 'types/community';
import { SelectOptions } from '../../components/elements/InputSelect';

const SIZE_LIST_BUSINESS = 10;

export const ListBusiness = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const [listBusiness, setListBusiness] = useState<Business[]>([]);
  const [communities, setCommunities] = useState<SelectOptions[]>([]);
  const { pagingInfo, setCurrentPage, setTotal } = usePaging({
    rowsPerPage: SIZE_LIST_BUSINESS
  });
  const handleChangePage = useCallback(
    (value: number) => {
      setCurrentPage(value);
      searchParams.set(`page`, value.toString());
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const handleFetchCommunity = useCallback(async () => {
    const optionCommunities = await axios.get(`/community`);
    const resCommunities = optionCommunities.data.communities as Community[];
    const comunitiesConvert = resCommunities.map((item: Community) => ({
      value: item.id,
      label: item.name
    }));
    setCommunities(comunitiesConvert);
  }, []);

  const fetchDataListBusiness = useCallback(
    async (page: string) => {
      const startDateParams =
        searchParams.get('startDate') && searchParams.get('startDate') !== 'Invalid Date'
          ? `&startDate=${searchParams.get('startDate')}`
          : '';
      const endDateParams =
        searchParams.get('endDate') && searchParams.get('endDate') !== 'Invalid Date'
          ? `&endDate=${searchParams.get('endDate')}`
          : '';
      const sortByScansParams = searchParams.get('sortByScans')
        ? `&sortByScans=${searchParams.get('sortByScans')}`
        : '';
      const sortByNameParams = searchParams.get('sortByName')
        ? `&sortByName=${searchParams.get('sortByName')}`
        : '';
      const communityIdParams = searchParams.get('communityId')
        ? `&communityId=${searchParams.get('communityId')}`
        : '';
      const res = await axios.get(
        `/admin/business?page=${page || 1}${communityIdParams}${startDateParams}${endDateParams}${sortByScansParams}${sortByNameParams}&size=${SIZE_LIST_BUSINESS}`
      );
      setListBusiness(res.data.business as Business[]);
      setCurrentPage(Number(page));
      setTotal(res.data.total);
      // eslint-disable-next-line
    }, [searchParams]);
  const { asyncCallback: handleFetchData } = useAsyncCallback(fetchDataListBusiness, []);
  const { asyncCallback: asyncFetchCommunity } = useAsyncCallback(handleFetchCommunity, []);

  useEffect(() => {
    asyncFetchCommunity();
  }, []);

  useEffect(() => {
    handleFetchData(searchParams.get('page') || 1);
    // eslint-disable-next-line
    }, [searchParams]);

  const onClickAction = useCallback((id: string) => {
    navigate(`/admin/business/edit/${id}`);
  }, []);

  const handleDelete = useCallback(async () => {
    await axios.delete(`/admin/business/${idDelete}`);
    setFlash({ type: 'success', message: 'Delete Business Successfully' });
    setShowModalConfirmDelete(false);
    const newList = listBusiness.filter((item) => item.id !== idDelete);
    setListBusiness(newList);
    const page = Number(searchParams.get('page'));
    if (newList.length === 0) {
      setSearchParams({ page: (page - 1).toString() });
    }
  }, [idDelete, listBusiness, searchParams.get('page')]);

  const { asyncCallback: asyncHandleDelete } = useAsyncCallback(handleDelete, []);

  const handleChangeFilter = useCallback(
    (fieldName: string, value: string) => {
      searchParams.set(`page`, '1');
      searchParams.set(`${fieldName}`, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onResetFilter = useCallback(() => {
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    searchParams.delete('communityId');
    setSearchParams(searchParams);
  }, [searchParams]);

  const handleSortByArea = useCallback(() => {
    searchParams.delete('sortByName');
    const valueSort =
      !searchParams.get('sortByScans') || searchParams.get('sortByScans') === 'asc'
        ? 'desc'
        : 'asc';
    searchParams.set('sortByScans', valueSort);
    setSearchParams(searchParams);
  }, [searchParams]);
  const handleSortByName = useCallback(() => {
    searchParams.delete('sortByScans');
    const valueSort =
      !searchParams.get('sortByName') || searchParams.get('sortByName') === 'asc' ? 'desc' : 'asc';
    searchParams.set('sortByName', valueSort);
    setSearchParams(searchParams);
  }, [searchParams]);

  const HEADER: Header[] = [
    {
      label: 'Number Of Scans',
      option: (
        <IconButton onClick={handleSortByArea} sx={{ width: '40px', height: '40px' }}>
          <img
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'cover' }}
            src={'/icons/icon_sort.svg'}
          />
        </IconButton>
      )
    },
    {
      label: 'Name',
      option: (
        <IconButton onClick={handleSortByName} sx={{ width: '40px', height: '40px' }}>
          <img
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'cover' }}
            src={'/icons/icon_sort.svg'}
          />
        </IconButton>
      )
    },
    {
      label: 'Description'
    },
    {
      label: 'Communities'
    },
    {
      label: 'Latitude'
    },
    {
      label: 'Longitude'
    },
    {
      label: 'Action',
      width: '300px'
    }
  ];

  return (
    <Stack width={'100%'}>
      <LayoutContent title={'Business List'}>
        <ListBusinessTable
          communities={communities}
          onResetFilter={onResetFilter}
          handleChangeFilter={handleChangeFilter}
          onClickAction={onClickAction}
          listBusiness={listBusiness}
          handleChangePage={handleChangePage}
          headers={HEADER}
          pagination={pagingInfo}
          onDelete={(id: string) => {
            setShowModalConfirmDelete(true);
            setIdDelete(id);
          }}
          valueFilter={{
            startDate: searchParams.get('startDate')
              ? new Date(searchParams.get('startDate') as string)
              : undefined,
            enDate: searchParams.get('endDate')
              ? new Date(searchParams.get('endDate') as string)
              : undefined,
            communityId: searchParams.get('communityId') || ''
          }}
        />
        <DialogConfirm
          isDelete
          isOpen={showModalConfirmDelete}
          onSubmit={asyncHandleDelete}
          onClose={() => setShowModalConfirmDelete(false)}
          textButton={'Delete'}
          textContent={'Are you sure you want to delete this content?'}
          textTitle={'Delete Business'}
        />
      </LayoutContent>
    </Stack>
  );
};
