import { Stack } from '@mui/material';
import { LayoutContent } from '../../components/templates/LayoutContent';
import { MilestoneForm, TMilestoneForm } from './components/MilestoneForm';
import { useParams } from 'react-router-dom';
import { useFlash } from 'contexts/flash';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const EditMilestonePage = () => {
  const { id } = useParams();
  const { setFlash } = useFlash();
  const [initValue, setInitValue] = useState<TMilestoneForm>();

  const getInitData = useCallback(async () => {
    const { data: res } = await axios.get(`/admin/milestones/${id}`);
    setInitValue(res?.milestone);
  }, [id]);

  const { asyncCallback: asyncGetInit } = useAsyncCallback(getInitData, []);
  useEffect(() => {
    asyncGetInit();
  }, []);

  const handleSubmit = useCallback(
    async (value: TMilestoneForm) => {
      await axios.put(`/admin/milestones/${id}`, value);
      setFlash({ type: 'success', message: 'Milestones update successful' });
    },
    [id]
  );

  const { asyncCallback: onSubmit } = useAsyncCallback(handleSubmit, []);

  return (
    <LayoutContent isBorder title={'Update Milestone'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <MilestoneForm initData={initValue} isEdit onSubmit={onSubmit} />
      </Stack>
    </LayoutContent>
  );
};
