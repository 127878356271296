import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from '../../../components/elements/InputSelect';
import { MarkerOption, OptionCategory } from 'types/marker';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { LayoutContent } from '../../../components/templates/LayoutContent';
import { MarkerForm, TMarkerForm } from '../components/MarkerForm';
import { useParams } from 'react-router-dom';

export const EditMarker = () => {
  const { id } = useParams();

  const [optionCategories, setOptionCategories] = useState<SelectOptions[]>([]);
  const [optionMarkers, setOptionMarkers] = useState<MarkerOption[]>([]);
  const [initValue, setInitValue] = useState<TMarkerForm>();
  const handleFetchOption = useCallback(async () => {
    const [optionType, optionMarker] = await Promise.all([
      axios.get('/admin/pin-categories'),
      axios.get('/admin/pins?size=100')
    ]);
    const resCategories = optionType.data.categories;
    const resMarker = optionMarker.data.pins as MarkerOption[];
    const categoriesConvert = resCategories.map((option: OptionCategory) => ({
      value: option.id,
      label: option.name
    }));
    setOptionCategories(categoriesConvert);
    setOptionMarkers(resMarker);
    const findMaker = resMarker.find((item) => item.id === id);
    if (!findMaker) return;
    const markerEdit = {
      name: findMaker.name,
      typeMarker: findMaker.categoryId,
      idIcon: findMaker.id,
      colorIcon: findMaker.color
    };
    setInitValue(markerEdit);
  }, [id]);

  const { asyncCallback: asyncFetchOption } = useAsyncCallback(handleFetchOption, []);

  useEffect(() => {
    asyncFetchOption();
  }, []);

  return (
    <LayoutContent isBorder title={'Create'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <MarkerForm
          id={id}
          isEdit
          optionCategories={optionCategories}
          optionMarkers={optionMarkers}
          initValue={initValue}
        />
      </Stack>
    </LayoutContent>
  );
};
